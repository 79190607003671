<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addModuleQuiz" ref="form">
        <v-card>
          <v-card-title>Add Quiz</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    counter="125"
                    label="Question"
                    hide-details="auto"
                    v-model="form.title"
                    :error-messages="form.$getError('title')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-list>
                <template v-for="(choice, index) in form.choices">
                  <v-list-item class="px-0" :key="index">
                    <v-list-item-action>
                      <v-checkbox
                        @click="changeChoiceAnswer(index)"
                        v-model="form.choices[index].is_correct"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            outlined
                            required
                            label="Choice"
                            hide-details="auto"
                            placeholder="Choice"
                            v-model="form.choices[index].choice"
                            :error-messages="
                              form.$getError(`choices.${index}.choice`)
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                    <v-list-item-action v-if="form.choices.length > 2">
                      <v-btn @click="removeChoiceField(index)" icon>
                        <v-icon color="grey lighten-1" small>
                          {{ icons.delete }}
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  @click="addChoiceField()"
                  class="mx-2"
                  fab
                  outlined
                  small
                  color="primary"
                >
                  <v-icon dark>
                    {{ icons.plus }}
                  </v-icon>
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary">Submit</v-btn>
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mdiPlus, mdiTrashCan } from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form({
        title: null,
        choices: [
          { choice: '', is_correct: true },
          { choice: '', is_correct: false }
        ]
      }),
      icons: {
        delete: mdiTrashCan,
        plus: mdiPlus
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    ...mapActions({
      addQuizAction: 'module/addQuiz'
    }),

    addModuleQuiz() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.addQuizAction({
        id: this.$route.params.id,
        formData: this.form.$data()
      })
        .then(() => {
          this.showSnackbar('Quiz successfully added!', 'success')
          this.closeDialog()
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    addChoiceField() {
      this.form.choices.push({
        choice: '',
        is_correct: false
      })
    },

    removeChoiceField(index) {
      this.form.choices.splice(index, 1)
    },

    changeChoiceAnswer(index) {
      this.form.choices.map((value, key) => {
        this.form.choices[key].is_correct = key === index
      })
    },

    closeDialog() {
      this.dialog = false
      this.form = new Form({
        title: null,
        choices: [
          { choice: '', is_correct: true },
          { choice: '', is_correct: false }
        ]
      })
      this.form.$clearErrors()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  }
}
</script>
