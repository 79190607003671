var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Modules ("+_vm._s(_vm.listMeta.total)+")")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.addModuleDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.add)+" ")]),_vm._v("Add Module ")],1)],1),_c('div',{staticClass:"mt-12 mb-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","clearable":"","hide-details":"","label":"Search","placeholder":"Search by name"},on:{"input":_vm.doFilter},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","items":_vm.status_items,"label":"Filter by Status"},on:{"change":_vm.doFilter},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.modules,"loading":_vm.loading,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.listMeta.total,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","items-per-page-options":[10, 20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status ? 'Active' : 'Inactive')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.addedDate)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","block":"","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'modules.details', params: { id: item.id } })}}},[_vm._v(" Edit ")])]}}],null,true)}),_c('AddModuleDialog',{model:{value:(_vm.addModuleDialog),callback:function ($$v) {_vm.addModuleDialog=$$v},expression:"addModuleDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }