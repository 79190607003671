<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'modules' }"
          >Modules</router-link
        >
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <v-btn @click="deleteConfirmDialog = true" color="error" text dark>
        <v-icon class="mr-3">
          {{ icons.delete }}
        </v-icon>
        Delete Module
      </v-btn>
    </v-app-bar>

    <v-divider></v-divider>

    <div class="pa-8" v-if="form">
      <v-form @submit.prevent="updateModule" ref="form">
        <v-row>
          <v-col cols="12" md="4">
            <h2 class="mr-auto">Module Details</h2>
          </v-col>
          <v-col cols="12" md="8" class="text-right">
            <input
              type="file"
              ref="videoAttachment"
              accept="video/mp4,video/quicktime,video/avi,video/mpeg"
              @change="updateVideoAttachment"
              style="display: none"
            />
            <v-btn
              class="mr-2"
              color="primary"
              width="150px"
              :loading="videoLoading"
              @click="changeVideoAttachment"
              depressed
            >
              {{ form.video ? 'Update Video' : 'Upload Video' }}
            </v-btn>
            <v-btn
              v-if="form.video"
              class="mr-2"
              color="primary"
              width="150px"
              :loading="videoLoading"
              @click="deleteVideoAttachment"
              depressed
            >
              Delete Video
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              width="150px"
              :loading="form.$busy"
              depressed
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-text-field
              outlined
              required
              counter="125"
              label="Module Name *"
              hide-details="auto"
              v-model="form.name"
              :error-messages="form.$getError('name')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-select
              :items="status_items"
              label="Status"
              outlined
              hide-details="auto"
              v-model="form.status"
              :error-messages="form.$getError('status')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <div>
              <input v-model="form.image" hidden />
              <v-img
                size="100%"
                class="mb-4"
                v-if="moduleCover"
                :src="moduleCover"
                :lazy-src="moduleCover"
              ></v-img>
              <v-btn @click="showImageUpload = true" color="primary">
                {{ moduleCover ? 'Change' : 'Add' }} cover image
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <v-divider class="mt-5"></v-divider>

      <div class="d-flex justify-space-between align-center my-8">
        <h2 class="mr-auto">Articles</h2>
        <v-btn
          color="primary"
          width="150px"
          @click="addArticle"
          :loading="addArticleLoading"
          depressed
        >
          Add Article
        </v-btn>
      </div>

      <div class="px-3">
        <v-row justify="center" v-if="module.articles.length > 0">
          <v-expansion-panels multiple accordion>
            <v-expansion-panel
              v-for="(article, index) in module.articles"
              :key="index"
            >
              <v-expansion-panel-header
                >Article {{ index + 1 }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <ArticleContentItem
                  :value="article"
                  :index="index"
                ></ArticleContentItem>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <div v-else>No article on this module.</div>
      </div>

      <v-divider class="mt-8"></v-divider>

      <div class="d-flex justify-space-between align-center my-8">
        <h2 class="mr-auto">Quizzes</h2>
        <v-btn
          @click="addModuleQuizDialog = true"
          color="primary"
          width="150px"
          depressed
        >
          Add Quiz
        </v-btn>
      </div>

      <div class="px-3">
        <v-row justify="center" v-if="module.quizzes.length > 0">
          <v-expansion-panels multiple accordion>
            <v-expansion-panel
              v-for="(quiz, index) in module.quizzes"
              :key="index"
            >
              <v-expansion-panel-header>{{
                quiz.title
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <QuizItem :value="quiz" :index="index"></QuizItem>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <div v-else>No quizzes on this module.</div>
      </div>
    </div>

    <image-cropper
      v-model="showImageUpload"
      :aspect-ratio="16 / 9"
      @change="imageChanged"
    />

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <AddModuleQuizDialog v-model="addModuleQuizDialog"></AddModuleQuizDialog>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete module"
      message="Are you sure you want to delete this module?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteModule"
    ></ConfirmModal>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ConfirmModal from '@/components/modals/ConfirmModal'
import ImageCropper from '@/components/modals/ImageCropper'
import ArticleContentItem from './components/ArticleContentItem'
import AddModuleQuizDialog from './components/AddModuleQuizDialog'
import QuizItem from './components/QuizItem'
import { mdiChevronLeft, mdiTrashCanOutline } from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'
import Form from '@/utils/form'
import { each } from 'lodash'

export default {
  name: 'ModuleDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    ImageCropper,
    ArticleContentItem,
    AddModuleQuizDialog,
    QuizItem
  },

  data() {
    return {
      form: null,
      deleteLoading: false,
      deleteConfirmDialog: false,
      addArticleLoading: false,
      addModuleQuizDialog: false,
      showImageUpload: false,
      moduleCover: null,
      status_items: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false }
      ],
      icons: {
        arrowLeft: mdiChevronLeft,
        delete: mdiTrashCanOutline
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      videoLoading: false
    }
  },

  computed: {
    ...mapState({
      module: state => state.module.moduleDetails
    })
  },

  methods: {
    ...mapActions({
      getModuleDetails: 'module/getModuleDetails',
      updateModuleDetails: 'module/updateModule',
      deleteModuleAction: 'module/deleteModule',
      addArticleAction: 'module/addArticle',
      updateModuleVideo: 'module/updateVideo',
      deleteModuleVideo: 'module/deleteVideo'
    }),

    ...mapMutations({
      clearModuleDetails: 'module/clearModuleDetails'
    }),

    async getModule() {
      await this.getModuleDetails(this.$route.params.id)
      this.form = new Form(this.module)

      if (this.module.image) {
        this.moduleCover = this.module.image.url
      }
    },

    updateModule() {
      const formData = new FormData()
      const { video, ...rest } = this.form.$data()

      formData.append('_method', 'PUT')

      each(rest, (value, key) => {
        formData.append(key, value)
      })

      if (!(formData.get('image') instanceof Blob)) {
        formData.delete('image')
      }

      this.form.$busy = true
      this.form.$clearErrors()
      this.$api
        .post(`/modules/${this.$route.params.id}`, formData)
        .then(() => {
          this.showSnackbar('Module details successfully updated!', 'success')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    addArticle() {
      this.addArticleLoading = true
      this.addArticleAction(this.$route.params.id)
        .then(() => {
          this.showSnackbar('Article successfully added!', 'success')
        })
        .finally(() => (this.addArticleLoading = false))
    },

    async deleteModule() {
      this.deleteLoading = true
      await this.deleteModuleAction(this.$route.params.id)
      this.deleteLoading = false
      this.$router.replace('/modules')
    },

    imageChanged(image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.moduleCover = reader.result
        this.form.image = image
      }
    },

    changeVideoAttachment() {
      this.$refs['videoAttachment'].value = null
      this.$refs['videoAttachment'].click()
    },

    async updateVideoAttachment(event) {
      this.videoLoading = true

      const data = new FormData()
      const file = event.target.files[0]

      data.append('video', file)
      data.append('id', this.$route.params.id)
      this.updateModuleVideo(data)
        .then(async () => {
          await this.getModule()
          this.showSnackbar('Video has been uploaded', 'success')
        })
        .catch(() => {
          this.showSnackbar(
            'There is something wrong with the attachment',
            'danger'
          )
        })
        .finally(() => {
          this.videoLoading = false
        })
    },

    async deleteVideoAttachment() {
      this.videoLoading = true

      this.deleteModuleVideo(this.$route.params.id)
        .then(async () => {
          await this.getModule()
          this.showSnackbar('Video has been deleted', 'success')
        })
        .catch(() => {
          this.showSnackbar('Something went wrong', 'danger')
        })
        .finally(() => {
          this.videoLoading = false
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  created() {
    this.getModule()
  },

  destroyed() {
    this.clearModuleDetails()
  }
}
</script>
