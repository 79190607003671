<template>
  <div>
    <v-form @submit.prevent="updateContent" ref="form">
      <div class="mb-5 d-flex justify-end">
        <v-btn
          @click="deleteConfirmDialog = true"
          color="error"
          class="mr-3"
          text
          dark
        >
          <v-icon class="mr-3">
            {{ icons.delete }}
          </v-icon>
          Delete Article
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          width="150px"
          :loading="form.$busy"
          depressed
        >
          Save
        </v-btn>
      </div>
      <quill-editor v-model="form.content" />
    </v-form>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete article"
      message="Are you sure you want to delete this article?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteArticle"
    ></ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import QuillEditor from '@/components/QuillEditor'
import { mdiTrashCanOutline } from '@mdi/js'
import { mapActions } from 'vuex'
import Form from '@/utils/form'

export default {
  props: {
    value: {
      type: Object
    },
    index: {
      type: Number
    }
  },

  components: {
    QuillEditor,
    ConfirmModal
  },

  data() {
    return {
      loading: false,
      deleteConfirmDialog: false,
      deleteArticleLoading: false,
      form: new Form(this.value),
      icons: {
        delete: mdiTrashCanOutline
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    ...mapActions({
      updateModuleArticleAction: 'module/updateModuleArticle',
      deleteModuleArticleAction: 'module/deleteArticle'
    }),

    updateContent() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateModuleArticleAction(this.form.$data())
        .then(() => {
          this.showSnackbar('Module article successfully updated!', 'success')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    deleteArticle() {
      this.deleteArticleLoading = true
      this.deleteModuleArticleAction({
        id: this.value.id,
        index: this.index
      })
        .then(() =>
          this.showSnackbar('Module article successfully deleted!', 'success')
        )
        .catch(() => this.showSnackbar('Something went wrong!', 'error'))
        .finally(() => {
          this.deleteArticleLoading = false
          this.deleteConfirmDialog = false
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
