<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-form @submit.prevent="updateQuiz" ref="form">
          <div class="mb-5 d-flex justify-end">
            <v-btn
              @click="deleteConfirmDialog = true"
              color="error"
              class="mr-3"
              text
              dark
              v-if="index != 0"
            >
              <v-icon class="mr-3">
                {{ icons.delete }}
              </v-icon>
              Delete Quiz
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              width="150px"
              :loading="form.$busy"
              depressed
            >
              Save
            </v-btn>
          </div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                required
                counter="125"
                label="Question"
                hide-details="auto"
                v-model="form.title"
                :error-messages="form.$getError('title')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-list>
            <template v-for="(choice, index) in form.choices">
              <v-list-item class="px-0" :key="index">
                <v-list-item-action>
                  <v-checkbox
                    @click="changeChoiceAnswer(index)"
                    v-model="form.choices[index].is_correct"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        outlined
                        required
                        label="Choice"
                        hide-details="auto"
                        placeholder="Choice"
                        v-model="form.choices[index].choice"
                        :error-messages="
                          form.$getError(`choices.${index}.choice`)
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action v-if="form.choices.length > 2">
                  <v-btn @click="removeChoiceField(index)" icon>
                    <v-icon color="grey lighten-1" small>
                      {{ icons.delete }}
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              @click="addChoiceField()"
              class="mx-2"
              fab
              outlined
              small
              color="primary"
            >
              <v-icon dark>
                {{ icons.plus }}
              </v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete quiz"
      message="Are you sure you want to delete this quiz?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteQuiz"
    ></ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mdiTrashCanOutline, mdiPlus } from '@mdi/js'
import { mapActions } from 'vuex'
import Form from '@/utils/form'

export default {
  props: {
    value: {
      type: Object
    },
    index: {
      type: Number
    }
  },

  components: {
    ConfirmModal
  },

  data() {
    return {
      deleteConfirmDialog: false,
      deleteQuizLoading: false,
      form: new Form(this.value),
      icons: {
        delete: mdiTrashCanOutline,
        plus: mdiPlus
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    ...mapActions({
      updateQuizAction: 'module/updateQuiz',
      deleteQuizAction: 'module/deleteQuiz'
    }),

    updateQuiz() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateQuizAction(this.form.$data())
        .then(() => {
          this.showSnackbar('Quiz successfully updated!', 'success')
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    deleteQuiz() {
      this.deleteQuizLoading = true
      this.deleteQuizAction({
        id: this.value.id,
        index: this.index
      })
        .then(() =>
          this.showSnackbar('Module quiz successfully deleted!', 'success')
        )
        .catch(() => this.showSnackbar('Something went wrong!', 'error'))
        .finally(() => {
          this.deleteQuizLoading = false
          this.deleteConfirmDialog = false
        })
    },

    addChoiceField() {
      this.form.choices.push({
        choice: '',
        is_correct: false
      })
    },

    removeChoiceField(index) {
      this.form.choices.splice(index, 1)
    },

    changeChoiceAnswer(index) {
      this.form.choices.map((value, key) => {
        this.form.choices[key].is_correct = key === index
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
