<template>
  <div
    class="v-text-field v-text-field--enclosed"
    :class="{
      'v-input--has-state error--text': hasError
    }"
  >
    <quill
      ref="editor"
      output="html"
      @input="onEditorChange($event)"
      :config="{
        ...config,
        placeholder
      }"
      :content="value"
    />
    <!-- error handler -->
    <div class="v-text-field__details mt-2">
      <div class="v-messages theme--light error--text">
        <div class="v-messages__wrapper">
          <v-slide-y-transition>
            <div v-show="error" class="v-messages__message">
              <span>{{ error }}</span>
            </div>
          </v-slide-y-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { first } from 'lodash'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Write something...'
    },
    errorMessages: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      config: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ align: [] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['link'],
              ['blockquote', 'code-block'],
              [{ color: [] }]
            ],
            handlers: {
              image: this.onQuillImageUpload
            }
          }
        },
        placeholder: 'Write an update...'
      }
    }
  },
  computed: {
    hasError() {
      return !!this.error
    },
    error() {
      return first(this.errorMessages)
    }
  },
  methods: {
    onEditorChange(content) {
      this.$emit('input', content)
    },
    onQuillImageUpload() {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.click()

      input.onchange = () => {
        const image = input.files[0]
        const form = new FormData()
        form.append('image', image)
        this.$api.post('/upload-images', form).then(({ data }) => {
          const uploadedImage = data.data
          const editor = this.$refs.editor.editor
          const range = editor.getSelection()
          editor.insertEmbed(range.index, 'image', uploadedImage.url)
        })
      }
    }
  }
}
</script>

<style>
.v-input--has-state .ql-container,
.v-input--has-state .ql-toolbar {
  border-color: currentColor;
  border-width: 2px;
}
</style>
