<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addModule" ref="form">
        <v-card>
          <v-card-title>Add Module</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    counter="125"
                    label="Module Name"
                    hide-details="auto"
                    v-model="form.name"
                    :error-messages="form.$getError('name')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary">Submit</v-btn>
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import Form from '@/utils/form'
import Module from '@/models/Module'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form({
        name: null
      })
    }
  },

  methods: {
    addModule() {
      this.form.$busy = true
      this.form.$clearErrors()
      new Module(this.form.$data())
        .save()
        .then(res => {
          this.$router.replace(`/modules/${res.id}`)
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    closeDialog() {
      this.dialog = false
      this.form = new Form({
        name: null
      })
      this.form.$clearErrors()
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  }
}
</script>
